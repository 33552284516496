import React, { useState, useEffect } from "react";
import SiteHeader from "../components/header";
import "../styles/workout.css";
import { Layout, Button, notification, Spin, Modal } from "antd";
import {
  HeartFilled,
  PlayCircleOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import clock from "../assets/images/Clock.png";
import {
  getSingleExcercise,
  removeFavouritesExercise,
  addFavouritesExercise,
  isAuthenticated,
} from "../api/requests";
import FooterDark from "../components/darkFooter";
import {
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
// import * as Sentry from "@sentry/react";
import ExerciseCircuitCard from "../components/ExerciseCircuitCard";
import {
  ExerciseCircuitSkeleton,
  ExerciseSkeletonScreen,
} from "../components/Skeleton/ExericsesSkeleton";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

const SingleExerciseDetails = () => {
  // Sentry.configureScope((scope) =>
  //   scope.setTransactionName("Workout Details ")
  // );
  const { exercise_id_url } = useParams();
  const exerciseUrl = `https://link.fitflexapp.com/?apn=fitflex.workout.fitness.weight.gym.fat.training&ibi=fitflex.workout.fitness.weight.gym.fat.training&isi=1613514782&link=https://link.fitflexapp.com/exerciseID?${exercise_id_url}`;
  const location = useLocation();
  const { t, i18n } = useTranslation();
  console.log("i18N IS :::", i18n);
  console.log("locations are :::", location.state);
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const [visible, setVisible] = useState(false);
  const [playVid, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFav, setIsFav] = useState(null);
  const [singleExercise, setSingleExercise] = useState();
  const [circuitData, setCircuitData] = useState({});
  const [tags, setTags] = useState([]);
  const jwt = localStorage.getItem("jwt");
  const token = JSON.parse(jwt);

  // console.log("t is :::", t);
  // const exerciseProps = JSON.parse(localStorage.getItem("exerciseProps"));
  // console.log(
  //   "the exercise props on single exercise page is :::",
  //   exerciseProps.name
  // );

  useEffect(() => {
    setLoading(true);
    const value = localStorage.getItem("jwt");
    const subStatus = localStorage.getItem("subscribed");
    console.log("SUB STATUES", subStatus);
    const initialValue = JSON.parse(value);
    let param = {
      language_id: i18n.language === "en" ? 7 : i18n.language === "id" ? 8 : 9,
    };
    getSingleExcercise(exercise_id_url, initialValue, param).then(
      (response) => {
        console.log("status is :::", response);
        if (response.status === 200) {
          console.log("single exercise data is :::", response.data);
          setSingleExercise(response.data.workout_detail);
          setCircuitData(response.data.circuit_data);
          setIsFav(response.data.workout_detail.is_favourite);
          setTags(response.data.workout_detail.tags);
          setLoading(false);
        }
      }
    );
  }, []);

  console.log("single exercise data is :::", singleExercise);
  const addFavExercise = async (exercise_id_url) => {
    const payload = {
      videoid: exercise_id_url,
      exercise: 1,
    };
    if (isFav === null) {
      await addFavouritesExercise(payload, token).then((response) => {
        if (response.status === 200) {
          console.log("add exercise response is :::", response);
          setIsFav(true);
          notification.success({
            message: "Success",
            style: {
              zIndex: 1,
            },
            description: "Exercise Added To Favourites",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      });
    } else {
      await removeFavouritesExercise(payload, token).then((response) => {
        if (response.status === 200) {
          console.log("remove exercise response is :::", response);
          setIsFav(null);

          notification.success({
            message: "Success",
            style: {
              zndex: 1,
            },
            description: "Exercise removed from Favourites",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      });
    }
  };

  // const antIcon = (
  //   <LoadingOutlined
  //     style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
  //     spin
  //   />
  // );
  // if (loading) {
  //   return (
  //     <div class="loader">
  //       <Spin className="spinner" indicator={antIcon} tip="Loading" />
  //     </div>
  //   );
  // }
  const handleCancel = () => {
    setVisible(false);
  };

  const Cap = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      {/* <div style={{ background: "black" }}>
        <p
          style={{
            color: "white",
            fontSize: "2em",
            fontWeight: "800",
            textShadow: " 4px 1px #000",
            marginTop: "50px",
            marginLeft: "50px",
            display: "flex",
            background: "black",
          }}
          className="hide-this"
        >
          {" "}
          Exercise
        </p>
      </div> */}
      <div className="single-exercise-container">
        {/* {console.log("VIDEO URL : is THIS :: ", vidData.media[0])} */}
        {!loading ? (
          <div className="exercise-img-and-details-div">
            {/* {vidData.content.subscribed === "Yes" &&
          vidData.content.daily_limit_reached === "No" ? (
            <div className="workout-details-image-div">
              <ReactPlayer
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "true",
                    },
                    tracks: arr2,
                  },
                }}
                url={vidData.media[0]}
                controls={true}
                width={"100%"}
                height={"auto"}
                playing={playVid}
                muted={true}
                onStart={() => {
                  const value = localStorage.getItem("jwt");
                  const initialValue = JSON.parse(value);
                  const params = {
                    video_id: video_id_url,
                    viewTime: played_time,
                    MET: MET_value,
                    weight: "",
                  };
                  setEffort(params, initialValue).then((res) => {
                    console.log("res on ended : ", res);
                  });
                }}
                onEnded={() => {
                  setPlaying(false);
                }}
                onProgress={(progress) => {
                  setPlayed_time(progress.playedSeconds);
                }}
                playIcon={
                  <PlayCircleOutlined
                    style={{
                      color: "#ff1e1e",
                      fontSize: "4em",
                      color: "#ff1e1e",
                    }}
                    onClick={() => {
                      setPlaying(true);
                    }}
                  />
                }
                light={playVid ? false : vidData.thumbs.original}
                className="react-workout-player"
              />
            </div>
          ) : ( */}
            <div className="exerciseImageDiv">
              <img
                src={singleExercise.cover_image}
                className="exercise-image"
              />
            </div>

            <div className="exerciseDetails-div">
              <p className="exercise-details-title-text">
                {singleExercise.name}
              </p>
              <div className="exercise-duration-and-views-div">
                <img src={clock} className="exercise-clock-n-eye" />
                <p className="exercise-duration-and-views-text">
                  {singleExercise.duration_in_minutes + " min"}
                </p>
                {/* <img
                src={eye}
                className="clock-n-eye"
                style={{ marginLeft: "10px" }}
              /> */}
                {/* <p className="duration-and-views-text"></p> */}
              </div>
              <div className="exercise-tags-div">
                {tags.map((item, key) => (
                  <p className="exercise-tag">{Cap(item)}</p>
                ))}
              </div>
              <p className="exercise-description-text">
                {singleExercise.description}
                {/* {console.log(tags)} */}
              </p>

              <div className="exercise-share-and-fav-div">
                {localStorage.getItem("subscribed") === "Yes" &&
                localStorage.getItem("dailyLimit") === "No" ? (
                  <Button
                    className="start-workout-btn"
                    onClick={() =>
                      navigate("/exercisePlayer", {
                        state: {
                          exercisePlayer: circuitData,
                          level: singleExercise.intensity,
                          name: singleExercise.name,
                          duration: singleExercise.duration_in_string,
                          calories:
                            singleExercise[
                              "energy-expenditure-in-kilo-calories"
                            ],
                        },
                      })
                    }
                  >
                    <PlayCircleOutlined />
                    {t("start_exercise")}
                  </Button>
                ) : (
                  <Button
                    className="start-workout-btn"
                    onClick={() => navigate("/plans")}
                  >
                    <PlayCircleOutlined />
                    {t("start_exercise")}
                  </Button>
                )}

                <div className="circular-btns-div">
                  <div
                    className={
                      isFav !== null ? "circular-div-colored" : "circular-div"
                    }
                    onClick={() => {
                      console.log(exercise_id_url);
                      token !== null
                        ? addFavExercise(exercise_id_url)
                        : navigate("/login");
                    }}
                  >
                    <HeartFilled
                      style={{ fontSize: "20px", marginTop: "17px" }}
                    />
                  </div>
                  <div
                    className="circular-div"
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    <ShareAltOutlined
                      style={{
                        fontSize: "20px",
                        marginTop: "17px",
                        marginLeft: "-3px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ExerciseSkeletonScreen />
        )}
        {/* <div className="workout-details-paragraph">
          {vidData.content.description}
        </div> */}
        {!loading ? (
          <ExerciseCircuitCard data={circuitData} />
        ) : (
          <ExerciseCircuitSkeleton />
        )}
        {/* <WhiteGetWorkout /> */}
      </div>
      <Modal
        visible={visible}
        title={"Share Post"}
        onCancel={handleCancel}
        className="custom-modal-styles"
        bodyStyle={{
          height: "auto",
          paddingTop: "70px",
          paddingBottom: "70px",
        }}
        footer={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: "wrap",
            // width: "80%",
          }}
        >
          <div
            className="circular-div"
            style={{ height: "60px" }}
            onClick={() => {
              notification.success({
                message: "Success",
                description: "Link Copied",
                onClick: () => {
                  console.log("Notification Clicked!");
                },
              });
              const el = document.createElement("textarea");
              el.value = exerciseUrl;
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
              setVisible(false);
            }}
          >
            <CopyOutlined
              style={{
                color: "white",
                fontSize: "24px",
                marginTop: "17px",
                marginLeft: "-2px",
              }}
            />
          </div>
          <FacebookShareButton url={exerciseUrl}>
            <FacebookIcon round={true} />
          </FacebookShareButton>
          <FacebookMessengerShareButton url={exerciseUrl}>
            <FacebookMessengerIcon round={true} />
          </FacebookMessengerShareButton>
          <WhatsappShareButton url={exerciseUrl}>
            <WhatsappIcon round={true} />
          </WhatsappShareButton>
          <EmailShareButton url={exerciseUrl}>
            <EmailIcon round={true} />
          </EmailShareButton>
          <LinkedinShareButton url={exerciseUrl}>
            <LinkedinIcon round={true} />
          </LinkedinShareButton>
          <TwitterShareButton url={exerciseUrl}>
            <TwitterIcon round={true} />
          </TwitterShareButton>
        </div>
      </Modal>
      <FooterDark />
    </div>
  );
};

export default SingleExerciseDetails;
